import axios from "@/utils/request";
export async function getList() {
    const map = {
        '0':  'https://api.todayinhistory.world/history/list',
        '1':  'https://api.dailyhistory.online/history/list',
    }
    const map2 = {
        '0': 'odd',
        '1': 'even'
    }
    
   const res = await axios({
        method: 'post',
        url: map[Math.round(Math.random())],
        data: {
            webIndex: map2[Math.round(Math.random())]
        },
        
    })
    return res.data
}

