<template>
  <div class="nav-bar-row">
    <div class="back" @click="goback">
      <img src="@/assets/nav-back.png" alt="">
    </div>
    <div class="head-bar-name">
      {{ title }}
    </div>
  </div>
</template>

<script>
import '@/css/nav_bar.scss';

export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    goback () {
      if (window.history.length > 1) {
        // 如果有上一级，返回
        this.$router.go(-1);
      } else {
        // 如果没有上一级，跳转到首页 '/'
        this.$router.push({
          name: 'home',
          query: Object.keys(this.$store.state.query).length === 0 ? undefined : this.$store.state.query
        })
      }
    }
  }
}
</script>
