import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			list: [],
			host: '',
			// deviceType: 'pc',
			deviceType: 'mobile',
			domainConfig: null,
			adsensConfig: {},
			showDebug: false,
			firebaseConfig: {
				apiKey: 'AIzaSyDPCzERUQwfWUSnOMxfKN_l1JI8B5jvPl0', 
				authDomain: 'web-a2bf7.firebaseapp.com', 
				projectId: 'web-a2bf7',
				storageBucket: 'web-a2bf7.appspot.com', 
				messagingSenderId: '780267482533', 
				appId: "1:780267482533:web:1d2e94983d349f16bf0c4c",
				measurementId: 'G-YWZFTJL9ZK',
			  },
			  query: {}
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
			setAdsense(state, adsenseConfig) {
				state.adsensConfig = adsenseConfig
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
			domainConfig: (state) => state.domainConfig,
		},
	})
}
