module.exports = {
	'historynow.click': {
		title: 'History Now',
		icon: '/icon/logo5.png',
		IAmTitle: 'History Now',
		IAmURL: 'historynow.click',
		IAMEMAIL: 'historynow.support@gmail.com',
		iamkeywords: 'History Now',

		ABOUTUS: 'Stay informed with timely historical insights and stories that shape our world. Explore key events, figures, and milestones that influence today’s global landscape.',

		firebase: {
			apiKey: 'AIzaSyCHu4Quq9SMB6lFMekleHi9pJYJWoQDEbQ',
			authDomain: 'webs-9476a.firebaseapp.com',
			projectId: 'webs-9476a',
			storageBucket: 'webs-9476a.firebasestorage.com',
			messagingSenderId: '96762571859',
			appId: '1:96762571859:web:216187745c5b38ec58aaa6',
			measurementId: 'G-NY7X5DKNH5',
		},

		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '6416014972', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '5047092983', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad3: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '8607064047', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad4: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '7213155546', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad5: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '6978459046', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad6: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '8794766309', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
		},
	},
	'historytoday.one': {
		title: 'History Today',
		icon: '/icon/logo6.png',
		IAmTitle: 'History Today',
		IAmURL: 'historytoday.one',
		IAMEMAIL: 'historytoday.support@gmail.com',
		iamkeywords: 'History Today',

		ABOUTUS: "Discover the past with daily historical insights. We bring key events, figures, and milestones to life, offering a deeper understanding of history's impact on today's world.",

		firebase: {
			apiKey: 'AIzaSyCHu4Quq9SMB6lFMekleHi9pJYJWoQDEbQ',
			authDomain: 'webs-9476a.firebaseapp.com',
			projectId: 'webs-9476a',
			storageBucket: 'webs-9476a.firebasestorage.com',
			messagingSenderId: '96762571859',
			appId: '1:96762571859:web:2c686460b7de8cbf58aaa6',
			measurementId: 'G-7ESPV27CV9',
		},

		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '6168602967', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '4855521290', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad3: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '3542439627', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad4: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '6786887353', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad5: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '4079725744', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad6: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '9916276284', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
		},
	},
	'todayinhistory.world': {
		title: 'TodayinHistory',
		icon: '/icon/logo11.png',
		IAmTitle: 'TodayinHistory',
		IAmURL: 'todayinhistory.world',
		IAMEMAIL: 'todayinhistory.support@gmail.com',
		iamkeywords: 'TodayinHistory',

		ABOUTUS: 'Welcome to todayinhistory.world, your go to resource for discovering significant events that have shaped our world, one day at a time.',

		firebase: {
			apiKey: 'AIzaSyBcS3cwlUXpK99s0FiNLcdhiTqTbqa8pRo',
			authDomain: 'webs-58a8d.firebaseapp.com',
			projectId: 'webs-58a8d',
			storageBucket: 'webs-58a8d.appspot.com',
			messagingSenderId: '730684174767',
			appId: '1:730684174767:web:bb6f4a647abdb5a80c3f5a',
			measurementId: 'G-SJEJW119SL',
		},

		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '7267717209', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '3152378222', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad3: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '3980918749', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad4: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '3328472195', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad5: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '2015390521', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad6: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '4038084486', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
		},
	},
	'dailyhistory.online': {
		title: 'Dailyhistory',
		icon: '/icon/logo12.png',
		IAmTitle: 'Dailyhistory',
		IAmURL: 'dailyhistory.online',
		IAMEMAIL: 'dailyhistory.support@gmail.com',
		iamkeywords: 'Dailyhistory',

		ABOUTUS: 'Welcome to dailyhistory.online, your go to resource for discovering significant events that have shaped our world, one day at a time.',

		firebase: {
			apiKey: 'AIzaSyBcS3cwlUXpK99s0FiNLcdhiTqTbqa8pRo',
			authDomain: 'webs-58a8d.firebaseapp.com',
			projectId: 'webs-58a8d',
			storageBucket: 'webs-58a8d.appspot.com',
			messagingSenderId: '730684174767',
			appId: '1:730684174767:web:3b21138190c8db380c3f5a',
			measurementId: 'G-XJLTH7VQH0',
		},

		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
			ad1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '1354755405', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '9490425983', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad3: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '3136900503', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad4: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '1839296550', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad5: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '7728592067', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad6: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '2725002812', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
		},
	},
	'historydailyevents.art': {
		title: 'History&Daily&Events Art',
		icon: '/icon/logo9.png',
		IAmTitle: 'History&Daily&Events Art',
		IAmURL: 'historydailyevents.art',
		IAMEMAIL: 'historydailyevents.support@gmail.com',
		iamkeywords: 'History&Daily&Events Art',

		ABOUTUS: 'Embrace the power of history with HistoryDailyEvents.art. Each day brings new insights into the events that defined our world, inspiring a deeper understanding of our shared past.',

		firebase: {
			apiKey: 'AIzaSyDPCzERUQwfWUSnOMxfKN_l1JI8B5jvPl0',
			authDomain: 'web-a2bf7.firebaseapp.com',
			projectId: 'web-a2bf7',
			storageBucket: 'web-a2bf7.appspot.com',
			messagingSenderId: '780267482533',
			appId: '1:780267482533:web:1d2e94983d349f16bf0c4c',
			measurementId: 'G-YWZFTJL9ZK',
		},

		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5103211735042916',
			ad1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '7267717209', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '3152378222', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad3: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '3980918749', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad4: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '3328472195', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad5: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '2015390521', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad6: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '4038084486', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
		},
	},
	'todayinhistory.click': {
		title: 'Today&In&History Click',
		icon: '/icon/logo8.png',
		IAmTitle: 'Today&In&History Click',
		IAmURL: 'todayinhistory.click',
		IAMEMAIL: 'todayinhistory.support@gmail.com',
		iamkeywords: 'Today&In&History Click',

		ABOUTUS: 'At todayinhistory.click, we bring you a daily journey through time. Explore the significant events that have shaped our civilization and gain insights into the rich tapestry of our shared history.',

		firebase: {
			apiKey: 'AIzaSyDPCzERUQwfWUSnOMxfKN_l1JI8B5jvPl0', 
			authDomain: 'web-a2bf7.firebaseapp.com', 
			projectId: 'web-a2bf7',
			storageBucket: 'web-a2bf7.appspot.com', 
			messagingSenderId: '780267482533', 
			appId: "1:780267482533:web:90d534418abd99f0bf0c4c",
			measurementId: 'G-WTW7Z2T6QE',
		},

		ads: 'google.com, pub-6466344935166719, DIRECT, f08c47fec0942fa0',
		adsense: {
			scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5103211735042916',
			ad1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '1354755405', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '9490425983', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad3: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '3136900503', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad4: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '1839296550', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad5: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '7728592067', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			ad6: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '2725002812', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
		},
	},
}
