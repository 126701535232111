<template>
	<CommonPage :title="domainConfig['iamkeywords']">
		<div class="index">
			<Wrapper color="#121212">
				<div class="top">
					<h1>This Day In History</h1>
					<div class="date">
						{{ `${month} | ${day}` }}
					</div>
				</div>
			</Wrapper>

			<component
				:is="contentComponent"
				:curOne="curOne"
				:sty="{ height: '45vh' }" />
			<Wrapper color="#ffffff">
				<div class="b">
					<Ad
						v-if="isPC"
						:ads="adsensConfig.ad5"
						:style="{ marginTop: isPC ? '50px' : 0, textAlign: 'center' }"></Ad>
					<div
						class="pa"
						v-html="curOne?.content"></div>
					<div class="son">
						<Ad :ads="adsensConfig.ad6"></Ad>

						<div class="text">Also on This Day in History</div>
						<div class="hs">
							<div
								v-for="item in list"
								:key="item?.title"
								@click="$router.push({ name: 'detail', params: { id: item?.id }, query: Object.keys($store.state.query).length === 0 ? undefined : $store.state.query })"
								class="itemBlock pointer"
								:style="{ background: `url(${item?.img}) center center / cover no-repeat, linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0))` }">
								<div class="e"></div>
								<div class="num">{{ item?.year }}</div>
								<div class="title">{{ item?.title }}</div>
							</div>
						</div>
					</div>
				</div>
			</Wrapper>
		</div>
	</CommonPage>
</template>
<script>
const _m = () => import('@/pages/components/bannerM.vue') //代码切割
const _pc = () => import('@/pages/components/bannerPC.vue') //代码切割
import '@/css/index.scss'
export default {
	metaInfo() {
		return {
			title: this.title,
			meta: [
				{
					name: 'description',
					content: this.description,
				},
				{
					name: 'title',
					content: this.title,
				},
				{
					property: 'og:title',
					content: this.title,
				},
				{
					property: 'og:description',
					content: this.description,
				},
			],
		}
	},
	data() {
		return {
			month: '',
			day: '',
			contentComponent: null,
			posi: '',
			quoteEng: '',
			chapters: [],
			curOne: null,
			list: [],
		}
	},
	computed: {
		description() {
			return `Understand the Terms of Use for ${this.domainConfig['IAmURL']}. Learn about user responsibilities and content guidelines.`
		},
		title() {
			return `${this.domainConfig['iamkeywords']} - Explore Daily Historical Events`
		},
	},
	created() {
		if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
			this.contentComponent = _pc
			return
		}
		this.contentComponent = _m
	},
	async activated() {
		let list = this.$store.state.list
		if (!list.length) {
			this.$router.push({
				name: 'home',
				query: Object.keys(this.$store.state.query).length === 0 ? undefined : this.$store.state.query,
			})
			return
		}
		console.log(list)
		this.curOne = list.find((e) => {
			if (e.id == this.$route.params.id) {
				return true
			}
		})
		console.log(this.$route.params.id)

		this.list = list.filter((e) => e.id != this.$route.params.id)

		const today = new Date()
		// 定义月份名称数组
		const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

		// 获取月份和日期
		const month = monthNames[today.getMonth()]
		const day = today.getDate()
		this.month = month
		this.day = day
	},
	async mounted() {
		if (typeof window !== 'undefined') {
			this.initAdTracking() //客户端交互
		}
	},
	methods: {
		//客户端交互 1121已优化版本
		initAdTracking() {
			this.activeIframe = null // 存储最近被点击的 iframe
			this.iframes = new Map() // 存储已添加的 iframe

			// 监听 iframe 的插入
			this.watchForIframeInsertion()

			// 设置页面可见性变化的处理
			this.setupVisibilityTracking()
		},
		watchForIframeInsertion() {
			const observer = new MutationObserver((mutations) => {
				mutations.forEach((mutation) => {
					mutation.addedNodes.forEach((node) => {
						if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
							this.addIframe(node)
						}
					})
				})
			})

			observer.observe(document.body, { childList: true, subtree: true })
		},
		addIframe(iframeElement) {
			if (this.iframes.has(iframeElement)) {
				return // 已经添加过，直接返回
			}

			this.iframes.set(iframeElement, {
				hasTracked: false,
				adSlot: iframeElement.closest('ins.adsbygoogle')?.dataset.adSlot || null,
			})

			// 如果还没有启动定时器，启动检查点击的定时器
			if (!this.checkInterval) {
				this.checkInterval = setInterval(this.checkIframeClick, 200)
			}
		},
		checkIframeClick() {
			const activeElement = document.activeElement
			if (activeElement && this.iframes.has(activeElement)) {
				const iframeData = this.iframes.get(activeElement)
				if (!iframeData.hasTracked) {
					this.handleIframeClick(activeElement)
					iframeData.hasTracked = true
					this.activeIframe = { element: activeElement, ...iframeData }
				}
			}
		},
		handleIframeClick(iframeElement) {
			console.log('Iframe 点击触发了!', iframeElement)
			const adContainer = iframeElement.closest('ins.adsbygoogle')

			if (adContainer) {
				// 获取父级 ins 元素的边界矩形
				const insRect = adContainer.getBoundingClientRect()

				// 计算父级 ins 元素相对于页面左上角的位置和高度
				const transformData = {
					insX: insRect.left,
					insY: insRect.top,
					insHeight: insRect.height,
					windowWidth: window.innerWidth,
					windowHeight: window.innerHeight,
				}

				// 调用核心功能
				this.$adClick(JSON.stringify(transformData))
				this.trackEvent('ad_iframe_click', 'click', {
					'data-ad-slot': adContainer.dataset.adSlot,
				})
			}
		},
		setupVisibilityTracking() {
			this.visibilityChangeHandler = () => {
				if (document.visibilityState === 'visible') {
					console.log('页面激活状态，返回页面')
					this.activeIframe = null // 重置最近被点击的 iframe 记录
				} else {
					console.log('页面非激活状态')
					if (this.activeIframe) {
						// 上报逻辑
						this.trackEvent('ad_iframe_click_leave', 'click', {
							'data-ad-slot': this.activeIframe.adSlot,
						})
					}
				}
			}

			document.addEventListener('visibilitychange', this.visibilityChangeHandler)
		},
		trackEvent(eventName, eventValue, dataAttributes) {
			// 事件追踪逻辑
			console.log(`${eventName} with value ${eventValue} on`, dataAttributes)

			// 调用核心功能
			if (typeof this.$eventrack === 'function') {
				this.$eventrack(eventName, eventValue, {
					...dataAttributes,
				})
			}
		},
		//客户端交互end
	},
}
</script>
